header#mainMenu.scrolled {
	box-shadow: 0px 6px 21px -9px rgba(0, 0, 0, 0.25);
	position: fixed;
    padding: 10px 0;
    top: 0;
    left: 0;
    width: 100%;
    // animation: slide-down 500ms;
    z-index: 99999;
    // background-color: $sec-color;
}

@keyframes slide-down {
	0% {
		transform: translate(0, -100%);
	}

	100% {
		transform: translate(0, 0%);
	}
}

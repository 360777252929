@import '../variables';
.s-map{
    background-color: $base-color;
}
.map{
    iframe{
        width: 100%;
        height: 400px;
        border-radius: 30px;
        @media (max-width: $bp-xs - 1) {
            height: 300px;
        }
    }
}
.map__intro{
    h2, p{
        color: #fff;
    }
}

@import '../variables';
.about-us__wrapper{
    display: flex;
    align-items: center;
    gap: 32px;
    background-color: $base-color;
    //padding: 40px;
    border-radius: 15px;
    flex-wrap: wrap;
    @media screen and (max-width: $bp-md - 1) {
       gap: 16px;
    }
}
.about-us__content{
    width: calc(50% - 16px);
    padding: 24px 0 24px 32px;
    @media screen and (max-width: $bp-md - 1) {
        width: 100%;
        padding: 16px;
        padding-top: 0;
    }
}
.about-us__img{
    width: calc(50% - 16px);
    margin-bottom: -3px;
    @media screen and (max-width: $bp-md - 1) {
        width: 100%;
        order: -1;
        height: 400px;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit:contain;
    }
}
.about-us__heading{
    color: #fff;
    text-align: left;
}
.about-us__description{
    color: #fff;
    margin-bottom: 32px;
    @media screen and (max-width: $bp-md - 1) {
       margin-bottom: 12px;
    }
}

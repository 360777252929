@import "../../variables";
// body.body-homepage{
//     padding-top: 0;
// }
body{
    padding-top: 86px;
    @media screen and (max-width: $bp-sm - 1) {
        padding-top: 51px;
    }
}
#mainMenu {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 10px 0;
    background-color: #5f6c6e;

    .header__wrapper {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }
    .header__navigation{
        margin: 0 0 0 auto ;
        display: flex;
        align-items: center;
    }
    .header__navigation-list{
        display: flex;
        gap: 32px;
        @media screen and (max-width: $bp-md - 1) {
            gap: 24px;
        }
        @media screen and (max-width: $bp-sm - 1) {
            gap: 16px;
        }
        li {
            a {
                display: inline-block;
                position: relative;
                padding: 22px 16px;
                color: #fff;
                font-weight: 900;
                font-size: 2.2rem;
                &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    top: calc(100% - 12px);
                    left: 50%;
                    height: 4px;
                    width: 0px;
                    transform: translate(-50%, 0);
                    background-color: $base-color;
                    transition: all 200ms ease-in-out;
                    border-radius: 5px;
                }
                &:hover, &.--active {
                    &:before {
                        width: 50px;
                    }
                }
            }
        }
    }
}

.header__logo {
    // width: 235px;
    max-width: 100%;
    height: auto;
    a{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
    }
    .header__logo-big-text{
        font-size: 3.1rem;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
    }
    .header__logo-small-text{
        font-size: 2rem;
        font-weight: 400;
        color: #fff;
    }
    @media screen and (max-width: $bp-md - 1) {
        .header__logo-big-text{
            font-size: 2.8rem;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
        }
    }
    @media screen and (max-width: $bp-sm - 1) {
        .header__logo-big-text{
            font-size: 2.4rem;
        }
        .header__logo-small-text{
            font-size: 1.8rem;
        }
    }
    @media screen and (max-width: $bp-xxs - 1) {
        .header__logo-big-text{
            font-size: 2rem;
        }
        .header__logo-small-text{
            font-size: 1.7rem;
        }
    }
}

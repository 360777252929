@import "../_variables.scss";

.mainContainer > section {
	padding: 70px 0;
	@media (max-width: $bp-xs - 1) {
		padding: 35px 0;
	}
}

//Small-container
.--small-container {
	@media (min-width: $bp-md - 1) {
		max-width: 950px !important;
	}
}

body{
    background-color: $sec-color;
}
.s-white{
    background-color: #fff;
}

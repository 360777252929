a[href$=".pdf"] {
	&::before {
		background-image: url("../img/pdf.svg");
	}
}
a[href$=".doc"],
a[href$=".docx"] {
	&::before {
		background-image: url("../img/docx.svg");
	}
}
a[href$=".xlsx"],
a[href$=".xls"] {
	&::before {
		background-image: url("../img/excel.svg");
	}
}

@import '../variables';
section.s-banner{
    position: relative;
    // background-color:rgba($color: $third-color, $alpha: 0.3);
    background-color: #fff;
    height: calc(100vh - 86px);
    @media screen and (max-width: $bp-lg - 1) {
        height: auto;
        padding: 240px 0 120px ;
    }
    @media screen and (max-width: $bp-sm - 1) {
        height: auto;
        padding: 160px 0 120px ;
    }
    @media screen and (max-width: $bp-xs - 1) {
        height: auto;
        padding: 120px 0 70px ;
    }
    @media screen and (max-width: $bp-xxs - 1) {
        height: auto;
        padding: 35px 0;
        display: flex;
        flex-wrap: wrap;
    }
    .container{
        height: 100%;
    }
}
.banner__wrapper{
    display: flex;
    align-items: center;
    height: 100%;
}
.banner__content{
    width: 50%;
    @media screen and (max-width: $bp-xxs - 1) {
        width: 100%;
    }
}
.banner__heading{
    text-align:left;
    position: relative;
    z-index: 2;
    line-height: 1.1;
    font-size: 5.6rem;
    // &:before {
    //     position: absolute;
    //     content: "";
    //     left: 0;
    //     top: 40px;
    //     width: 100%;
    //     height: 34px;
    //     background-color: rgba($color: $base-color, $alpha: 0.3);
    //     z-index: -1;
    //     @media screen and (max-width: $bp-md - 1) {
    //         top: 28px;
    //         height: 20px;
    //     }
    //     @media screen and (max-width: $bp-sm - 1) {
    //        top: 24px;
    //     }
    //     @media screen and (max-width: $bp-xs - 1) {
    //         top: 20px;
    //         height: 12px;
    //     }
    // }
    @media screen and (max-width: $bp-md - 1) {
        font-size: 3.8rem;
        margin-bottom: 12px;
    }
    @media screen and (max-width: $bp-sm - 1) {
        font-size: 3rem;
    }
    @media screen and (max-width: $bp-xs - 1) {
        font-size: 2.6rem;
    }
}
.banner__perex{
    font-size: 3rem;
    @media screen and (max-width: $bp-md - 1) {
        font-size: 2.6rem;
    }
    @media screen and (max-width: $bp-sm - 1) {
        font-size: 2rem;
    }
}
.banner__image{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 90%;
    @media screen and (max-width: $bp-lg - 1) {
       height: 100%;
    }
    @media screen and (max-width: $bp-sm - 1) {
        width: 55%;
    }
    @media screen and (max-width: $bp-xxs - 1) {
       position: relative;
       width: 100%;
       margin-bottom: 16px;
       height: 250px;
       order: -1;
    }
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center bottom;
    }
}

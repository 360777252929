@import "../../variables";
// Hamburger default
.header__wrapper {
	label.header__hamburger {
		z-index: 9999;
		cursor: pointer;
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: flex-end;
		position: relative;
		order: 10;
		input {
			display: none;
		}
		span {
			display: inline-block;
			width: 30px;
			height: 4px;
			border-radius: 3px;
			background: #fff;
			margin: 3px 0 3px auto;
			transition: all 200ms ease-in-out;
			&:nth-child(3) {
				width: 25px;
			}
		}
		&:hover span:nth-child(3) {
			width: 30px;
		}
	}
}

// Changes after menu is opened - Hamburger
@media (max-width: $nav-bp) {
	.header__wrapper label.header__hamburger {
		display: flex;
		transform-origin: center center;
	}
	body.menuOpen {
        overflow: hidden;
		header#mainMenu nav {
			opacity: 1;
			display: block;
            ul{
                flex-direction: column;
                gap: 8px;
            }
		}
	}
	body.menuOpen {
		.header__wrapper label.header__hamburger {
			display: flex;
			span {
				background-color: #fff;
				transform-origin: center center;
			}
			span:nth-child(3) {
				width: 0;
				opacity: 0;
			}
			span:nth-child(2) {
				transform: translate(0, 10px) rotate(45deg);
			}
			span:nth-child(4) {
				transform: translate(0, -10px) rotate(-45deg);
			}
		}
	}
	// Display anim
	.header__wrapper nav ul li {
		ul {
			opacity: 1;
		}
		&:hover > ul,
		&:focus > ul {
			display: block;
			opacity: 1;
			z-index: 100;
		}
	}
}

@media screen and (max-width: $bp-sm - 1) {
    #mainMenu {
        .header__logo,.header__contact{
            z-index: 9999;
        }
        nav {
            position: fixed;
            left: 0;
            top: 0;
            z-index: 999;
            width: 100%;
            min-height: 100vh;
            height: 100%;
            padding: $nav-height 10px 40px 10px;
            overflow: auto;
            opacity: 0;
            display: none;
            transition: all 300ms ease-in-out;
            background-color: #5f6c6e;
            li a{
                display: block;
                font-size: 2.2rem;
                width: 100%;
                text-align: center;

            }
        }
    }
    .header__language{
        margin-right: 16px;
    }
}

@import '../variables';
.s-footer{
    padding: 35px 0;
    background-color: #fff;
}
.footer__wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    @media screen and (max-width: $bp-sm - 1) {
       grid-template-columns: 1fr;
    }
}
.footer__col{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.footer__image{
    height: 80px;
    width: auto;
    object-position: left;
    object-fit: contain;
    margin-left: -20px;
}
.footer__name{
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 6px;
    @media screen and (max-width: $bp-xs - 1) {
        font-size: 28px;
     }
}
.footer__web{
    span{
        font-weight: 700;
    }
}
.team__phone, .team__email{
    margin-bottom: 6px;
}
.s-disclaimer{
    padding: 2rem 0;
    background-color: $sec-color;
}

.foonter__disclaimer-wrapper{
    text-align: center;
    line-height: 1.4;

}
.footer__disclaimer-link{
    color: $base-color;
    font-weight: 700;
}

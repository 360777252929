/* Colors */
/* Menu */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Global styles TOC
	Reseter
	Fonts
	Typo
	Global classes
	Bootstrap alter
*/
/* Colors */
/* Menu */
/* Typo */
button,
input[type=submit] {
  cursor: pointer;
}

* {
  outline: none;
}

a {
  text-decoration: none;
  transition: 200ms ease-in-out;
  color: #00A1B5;
}

li {
  font-size: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 700;
}

img.no-resize {
  max-width: none;
}

body .fs-40 {
  font-size: 40px;
}
body .fs-36 {
  font-size: 36px;
}
body .fs-34 {
  font-size: 34px;
}
body .fs-30 {
  font-size: 30px;
}
body .fs-24 {
  font-size: 24px;
}
body .fs-20 {
  font-size: 20px;
}
body .fs-18 {
  font-size: 18px;
}
body .fs-16 {
  font-size: 16px;
}
body .fs-15 {
  font-size: 15px;
}
body .fs-14 {
  font-size: 14px;
}
body .fw-300 {
  font-weight: 300;
}
body .fw-400 {
  font-weight: 400;
}
body .fw-500 {
  font-weight: 500;
}
body .fw-700 {
  font-weight: 700;
}
body .text-center {
  text-align: center;
}
body .text-left {
  text-align: left;
}
body .text-right {
  text-align: right;
}
body .td-underline {
  text-decoration: underline;
}
body .text-green {
  color: #00A1B5;
}
body .monserat {
  font-family: "Montserrat", sans-serif;
}

.no-border {
  border: none !important;
}

.small {
  font-size: 0.8em;
}

.big {
  font-size: 1.6em;
}

.nowrap {
  white-space: nowrap;
}

.turncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 992px) {
  .turncate-sm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.w-100 {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.td-under {
  text-decoration: underline;
}

.mb-1 {
  margin-bottom: 25px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* Global classes */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.jc-center {
  justify-content: center;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.ac-center {
  align-content: center;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.jc-space-between {
  justify-content: space-between;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.row .col {
  flex-grow: 1;
}
.row .col-ng {
  flex-grow: 0;
}

.ma {
  margin: auto;
}

.no-margin {
  margin: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding {
  padding: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Own bootstrap alternative */
.container-small,
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 100%;
}

.col-1 {
  padding: 0 10px;
  width: calc((1 / 12) * 100%);
}

.col-2 {
  padding: 0 10px;
  width: calc((2 / 12) * 100%);
}

.col-3 {
  padding: 0 10px;
  width: calc((3 / 12) * 100%);
}

.col-4 {
  padding: 0 10px;
  width: calc((4 / 12) * 100%);
}

.col-5 {
  padding: 0 10px;
  width: calc((5 / 12) * 100%);
}

.col-6 {
  padding: 0 10px;
  width: calc((6 / 12) * 100%);
}

.col-7 {
  padding: 0 10px;
  width: calc((7 / 12) * 100%);
}

.col-8 {
  padding: 0 10px;
  width: calc((8 / 12) * 100%);
}

.col-9 {
  padding: 0 10px;
  width: calc((9 / 12) * 100%);
}

.col-10 {
  padding: 0 10px;
  width: calc((10 / 12) * 100%);
}

.col-11 {
  padding: 0 10px;
  width: calc((11 / 12) * 100%);
}

.col-12 {
  padding: 0 10px;
  width: calc((12 / 12) * 100%);
}

@media (min-width: 768px) {
  .container-small,
  .container {
    width: 750px;
  }
  .col-xs-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-xs-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-xs-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-xs-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-xs-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-xs-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-xs-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-xs-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-xs-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-xs-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-xs-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-xs-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 992px) {
  .container-small,
  .container {
    width: 970px;
  }
  .col-sm-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-sm-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-sm-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-sm-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-sm-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-sm-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-sm-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-sm-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-sm-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-sm-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-sm-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-sm-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 1200px) {
  .container-small,
  .container {
    width: 1170px;
  }
  .col-md-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-md-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-md-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-md-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-md-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-md-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-md-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-md-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-md-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-md-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-md-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-md-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 1450px) {
  .container-small,
  .container {
    width: 1400px;
  }
  .col-lg-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-lg-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-lg-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-lg-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-lg-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-lg-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-lg-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-lg-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-lg-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-lg-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-lg-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-lg-12 {
    width: calc((12 / 12) * 100%);
  }
}
table {
  margin-bottom: 20px;
  font-size: 16px;
}
table td {
  padding: 10px 25px;
}

.table-stripped tr:nth-child(odd) {
  background: #f2f2f2;
}

.table-full {
  width: 100%;
}

@media (max-width: 555px) {
  .hidden-xxs-down {
    display: none;
  }
  table {
    font-size: 14px;
  }
  table td {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .hidden-xs-down {
    display: none;
  }
}
@media (max-width: 991px) {
  .hidden-sm-down {
    display: none;
  }
}
@media (max-width: 1199px) {
  .hidden-md-down {
    display: none;
  }
}
@media (max-width: 1449px) {
  .hidden-lg-down {
    display: none;
  }
}
.d-none {
  display: none;
  visibility: hidden;
  opacity: 0;
  z-index: -2000;
}

.visible-only-xxs,
.visible-only-xs,
.visible-only-sm,
.visible-only-md,
.visible-only-lg {
  display: none;
}

@media (max-width: 555px) {
  .visible-only-xxs {
    display: inline-block;
  }
}
@media screen and (min-width: 556px) and (max-width: 767px) {
  .visible-only-xs {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .visible-only-xs {
    display: inline-block;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .visible-only-sm {
    display: inline-block;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1449px) {
  .visible-only-md {
    display: inline-block;
  }
}
.btn-mobile {
  display: none;
}

@media (max-width: 767px) {
  .btn-desktop {
    display: none;
  }
  .btn-mobile {
    display: table;
  }
}
@media (max-width: 1749px) {
  .flex-wrap-xl {
    flex-wrap: wrap;
  }
}
@media (max-width: 1449px) {
  .flex-wrap-lg {
    flex-wrap: wrap;
  }
}
@media (max-width: 1199px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .flex-wrap-sm {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .flex-wrap-xs {
    flex-wrap: wrap;
  }
}
@media (max-width: 555px) {
  .flex-wrap-xxs {
    flex-wrap: wrap;
  }
}
@media (max-width: 354px) {
  .flex-wrap-xxxs {
    flex-wrap: wrap;
  }
}
/* Colors */
/* Menu */
html {
  font-size: 10px;
}

body {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  color: #27292E;
  font-size: 1.8rem;
}

textarea,
button,
input {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-size: 6.3rem;
  font-weight: 900;
  margin-bottom: 30px;
  line-height: 1;
  color: #27292E;
  text-align: center;
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 3.4rem;
    margin-bottom: 15px;
  }
}

h2 {
  font-family: "Raleway", sans-serif;
  font-size: 4.2rem;
  margin-bottom: 22px;
  font-weight: 900;
  line-height: 1.1;
  color: #27292E;
  text-align: center;
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 2.7rem;
    margin-bottom: 10px;
  }
}

h3 {
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
  position: relative;
  font-weight: 800;
  margin-bottom: 15px;
  line-height: 1.1;
  color: #27292E;
}
@media screen and (max-width: 767px) {
  h3 {
    font-size: 2.2rem;
    margin-bottom: 10px;
  }
}

h4 {
  font-family: "Raleway", sans-serif;
  font-size: 2.2rem;
  position: relative;
  font-weight: 800;
  margin-bottom: 8px;
  line-height: 1.1;
  color: #27292E;
}
@media screen and (max-width: 767px) {
  h4 {
    font-size: 1.8rem;
  }
}

p {
  font-size: 1.8rem;
  line-height: 1.6;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  p {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
}

div,
ul,
ol,
table {
  font-size: 1.8rem;
  color: #27292E;
}

a,
li,
td,
span,
input,
textarea,
label,
th {
  font-size: inherit;
  color: #27292E;
}

.p-middle {
  width: 1100px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 50px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-middle {
    margin-bottom: 20px;
  }
}

.mainContainer strong {
  font-weight: 700 !important;
}
.mainContainer table {
  display: block;
  width: 100%;
  overflow-x: auto;
  margin: 0 0;
  margin-bottom: 40px;
}
.mainContainer table thead {
  text-align: left;
  font-size: 1.6rem;
}
.mainContainer table thead tr {
  background-color: rgba(39, 41, 46, 0.16);
}
.mainContainer table thead th {
  border: 1px solid #27292E;
  padding: 10px;
  padding-left: 25px;
}
.mainContainer table tbody tr {
  background: #fff;
}
.mainContainer table tbody tr:nth-child(odd) {
  background-color: #efefef;
}
.mainContainer table td {
  border: 1px solid #27292E;
  padding: 17px 25px;
}
.mainContainer table td a {
  font-weight: 700;
}
.mainContainer table td p {
  margin-bottom: 0;
}
.mainContainer ol,
.mainContainer ul {
  margin-bottom: 16px;
}
.mainContainer ol li,
.mainContainer ul li {
  line-height: 1.6;
  margin-bottom: 5px;
}
.mainContainer ul li {
  position: relative;
  padding-left: 15px;
}
.mainContainer ul li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: 0.6em;
  left: 0;
  background-color: #27292E;
  border-radius: 50%;
}
.mainContainer ul li ul {
  margin-bottom: 0;
}
.mainContainer ul li ul > li {
  position: relative;
  padding-left: 15px;
}
.mainContainer ul li ul > li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: 0.6em;
  left: 0;
  background-color: #eceff1;
  border-radius: 50%;
}
.mainContainer ol {
  list-style-type: revert;
}
.mainContainer ol li {
  margin-left: 20px;
}
.mainContainer p > a {
  font-weight: 700;
  text-decoration: underline;
}

/* Colors */
/* Menu */
.icon:before {
  content: "";
  width: 1em;
  height: 1em;
  vertical-align: middle;
  display: inline-block;
  margin-right: 1em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.icon.icon-2x:before {
  width: 2em;
  height: 2em;
}
.icon.icon-3x:before {
  width: 3em;
  height: 3em;
}
.icon.icon-5x:before {
  width: 5em;
  height: 5em;
}
.icon.icon-marker::before {
  background-image: url("../img/marker.svg");
}

/* Fonts */
/*
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-light.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-light.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-light.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-light.svg') format('svg'); 
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-regular.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-regular.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-regular.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-regular.svg') format('svg'); 
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-mediumitalic.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-mediumitalic.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-mediumitalic.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-mediumitalic.svg') format('svg'); 
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-medium.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-medium.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-medium.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-medium.svg') format('svg'); 
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-semibold.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-semibold.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-semibold.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-semibold.svg') format('svg'); 
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-bold.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-bold.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-bold.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-bold.svg') format('svg'); 
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Light.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Regular.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Bold.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
*/
/* Colors */
/* Menu */
a.fill-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

body section .btn-std,
.btn-std {
  background-color: #00A1B5;
  color: #fff;
  display: table;
  line-height: 1;
  margin: 10px auto;
  padding: 16px 26px;
  text-align: center;
  max-width: 100%;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out !important;
  font-weight: 800;
}
body section .btn-std.btn-reve,
.btn-std.btn-reve {
  background: #fff;
  color: #00A1B5;
}
body section .btn-std.btn-orange,
.btn-std.btn-orange {
  color: #27292E;
  background: linear-gradient(270deg, rgb(255, 179, 71) 0%, rgb(255, 204, 51) 100%);
}
body section .btn-std.btn-left,
.btn-std.btn-left {
  margin: 10px auto 10px 0;
}
body section .btn-std.btn-right,
.btn-std.btn-right {
  margin: 10px 0 10px auto;
}
body section .btn-std.btn-center,
.btn-std.btn-center {
  margin: 10px auto;
}
body section .btn-std:hover,
.btn-std:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #00A1B5;
  color: #00A1B5;
}
body section .btn-std:hover.btn-reve,
.btn-std:hover.btn-reve {
  background-color: #00A1B5;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
}

/* Colors */
/* Menu */
.mainContainer > section {
  padding: 70px 0;
}
@media (max-width: 767px) {
  .mainContainer > section {
    padding: 35px 0;
  }
}

@media (min-width: 1199px) {
  .--small-container {
    max-width: 950px !important;
  }
}

body {
  background-color: #eceff1;
}

.s-white {
  background-color: #fff;
}

/* Colors */
/* Menu */
/* Colors */
/* Menu */
/* Colors */
/* Menu */
body {
  padding-top: 86px;
}
@media screen and (max-width: 991px) {
  body {
    padding-top: 51px;
  }
}

#mainMenu {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 10px 0;
  background-color: #5f6c6e;
}
#mainMenu .header__wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
#mainMenu .header__navigation {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
}
#mainMenu .header__navigation-list {
  display: flex;
  gap: 32px;
}
@media screen and (max-width: 1199px) {
  #mainMenu .header__navigation-list {
    gap: 24px;
  }
}
@media screen and (max-width: 991px) {
  #mainMenu .header__navigation-list {
    gap: 16px;
  }
}
#mainMenu .header__navigation-list li a {
  display: inline-block;
  position: relative;
  padding: 22px 16px;
  color: #fff;
  font-weight: 900;
  font-size: 2.2rem;
}
#mainMenu .header__navigation-list li a:before {
  content: "";
  position: absolute;
  display: inline-block;
  top: calc(100% - 12px);
  left: 50%;
  height: 4px;
  width: 0px;
  transform: translate(-50%, 0);
  background-color: #00A1B5;
  transition: all 200ms ease-in-out;
  border-radius: 5px;
}
#mainMenu .header__navigation-list li a:hover:before, #mainMenu .header__navigation-list li a.--active:before {
  width: 50px;
}

.header__logo {
  max-width: 100%;
  height: auto;
}
.header__logo a {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.header__logo .header__logo-big-text {
  font-size: 3.1rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.header__logo .header__logo-small-text {
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
}
@media screen and (max-width: 1199px) {
  .header__logo .header__logo-big-text {
    font-size: 2.8rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
  }
}
@media screen and (max-width: 991px) {
  .header__logo .header__logo-big-text {
    font-size: 2.4rem;
  }
  .header__logo .header__logo-small-text {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 555px) {
  .header__logo .header__logo-big-text {
    font-size: 2rem;
  }
  .header__logo .header__logo-small-text {
    font-size: 1.7rem;
  }
}

header#mainMenu.scrolled {
  box-shadow: 0px 6px 21px -9px rgba(0, 0, 0, 0.25);
  position: fixed;
  padding: 10px 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}

@keyframes slide-down {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0%);
  }
}
/* Colors */
/* Menu */
.header__wrapper label.header__hamburger {
  z-index: 9999;
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  order: 10;
}
.header__wrapper label.header__hamburger input {
  display: none;
}
.header__wrapper label.header__hamburger span {
  display: inline-block;
  width: 30px;
  height: 4px;
  border-radius: 3px;
  background: #fff;
  margin: 3px 0 3px auto;
  transition: all 200ms ease-in-out;
}
.header__wrapper label.header__hamburger span:nth-child(3) {
  width: 25px;
}
.header__wrapper label.header__hamburger:hover span:nth-child(3) {
  width: 30px;
}

@media (max-width: 991px) {
  .header__wrapper label.header__hamburger {
    display: flex;
    transform-origin: center center;
  }
  body.menuOpen {
    overflow: hidden;
  }
  body.menuOpen header#mainMenu nav {
    opacity: 1;
    display: block;
  }
  body.menuOpen header#mainMenu nav ul {
    flex-direction: column;
    gap: 8px;
  }
  body.menuOpen .header__wrapper label.header__hamburger {
    display: flex;
  }
  body.menuOpen .header__wrapper label.header__hamburger span {
    background-color: #fff;
    transform-origin: center center;
  }
  body.menuOpen .header__wrapper label.header__hamburger span:nth-child(3) {
    width: 0;
    opacity: 0;
  }
  body.menuOpen .header__wrapper label.header__hamburger span:nth-child(2) {
    transform: translate(0, 10px) rotate(45deg);
  }
  body.menuOpen .header__wrapper label.header__hamburger span:nth-child(4) {
    transform: translate(0, -10px) rotate(-45deg);
  }
  .header__wrapper nav ul li ul {
    opacity: 1;
  }
  .header__wrapper nav ul li:hover > ul, .header__wrapper nav ul li:focus > ul {
    display: block;
    opacity: 1;
    z-index: 100;
  }
}
@media screen and (max-width: 991px) {
  #mainMenu .header__logo, #mainMenu .header__contact {
    z-index: 9999;
  }
  #mainMenu nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding: 86px 10px 40px 10px;
    overflow: auto;
    opacity: 0;
    display: none;
    transition: all 300ms ease-in-out;
    background-color: #5f6c6e;
  }
  #mainMenu nav li a {
    display: block;
    font-size: 2.2rem;
    width: 100%;
    text-align: center;
  }
  .header__language {
    margin-right: 16px;
  }
}
/* Colors */
/* Menu */
.s-footer {
  padding: 35px 0;
  background-color: #fff;
}

.footer__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}
@media screen and (max-width: 991px) {
  .footer__wrapper {
    grid-template-columns: 1fr;
  }
}

.footer__col {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer__image {
  height: 80px;
  width: auto;
  object-position: left;
  object-fit: contain;
  margin-left: -20px;
}

.footer__name {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 6px;
}
@media screen and (max-width: 767px) {
  .footer__name {
    font-size: 28px;
  }
}

.footer__web span {
  font-weight: 700;
}

.team__phone, .team__email {
  margin-bottom: 6px;
}

.s-disclaimer {
  padding: 2rem 0;
  background-color: #eceff1;
}

.foonter__disclaimer-wrapper {
  text-align: center;
  line-height: 1.4;
}

.footer__disclaimer-link {
  color: #00A1B5;
  font-weight: 700;
}

/* Colors */
/* Menu */
/* Colors */
/* Menu */
a[href$=".pdf"]::before {
  background-image: url("../img/pdf.svg");
}

a[href$=".doc"]::before,
a[href$=".docx"]::before {
  background-image: url("../img/docx.svg");
}

a[href$=".xlsx"]::before,
a[href$=".xls"]::before {
  background-image: url("../img/excel.svg");
}

/* Colors */
/* Menu */
.s-map {
  background-color: #00A1B5;
}

.map iframe {
  width: 100%;
  height: 400px;
  border-radius: 30px;
}
@media (max-width: 767px) {
  .map iframe {
    height: 300px;
  }
}

.map__intro h2, .map__intro p {
  color: #fff;
}

/* Colors */
/* Menu */
section.s-banner {
  position: relative;
  background-color: #fff;
  height: calc(100vh - 86px);
}
@media screen and (max-width: 1449px) {
  section.s-banner {
    height: auto;
    padding: 240px 0 120px;
  }
}
@media screen and (max-width: 991px) {
  section.s-banner {
    height: auto;
    padding: 160px 0 120px;
  }
}
@media screen and (max-width: 767px) {
  section.s-banner {
    height: auto;
    padding: 120px 0 70px;
  }
}
@media screen and (max-width: 555px) {
  section.s-banner {
    height: auto;
    padding: 35px 0;
    display: flex;
    flex-wrap: wrap;
  }
}
section.s-banner .container {
  height: 100%;
}

.banner__wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.banner__content {
  width: 50%;
}
@media screen and (max-width: 555px) {
  .banner__content {
    width: 100%;
  }
}

.banner__heading {
  text-align: left;
  position: relative;
  z-index: 2;
  line-height: 1.1;
  font-size: 5.6rem;
}
@media screen and (max-width: 1199px) {
  .banner__heading {
    font-size: 3.8rem;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 991px) {
  .banner__heading {
    font-size: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .banner__heading {
    font-size: 2.6rem;
  }
}

.banner__perex {
  font-size: 3rem;
}
@media screen and (max-width: 1199px) {
  .banner__perex {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 991px) {
  .banner__perex {
    font-size: 2rem;
  }
}

.banner__image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 90%;
}
@media screen and (max-width: 1449px) {
  .banner__image {
    height: 100%;
  }
}
@media screen and (max-width: 991px) {
  .banner__image {
    width: 55%;
  }
}
@media screen and (max-width: 555px) {
  .banner__image {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    height: 250px;
    order: -1;
  }
}
.banner__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center bottom;
}

/* Colors */
/* Menu */
.about-us__wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
  background-color: #00A1B5;
  border-radius: 15px;
  flex-wrap: wrap;
}
@media screen and (max-width: 1199px) {
  .about-us__wrapper {
    gap: 16px;
  }
}

.about-us__content {
  width: calc(50% - 16px);
  padding: 24px 0 24px 32px;
}
@media screen and (max-width: 1199px) {
  .about-us__content {
    width: 100%;
    padding: 16px;
    padding-top: 0;
  }
}

.about-us__img {
  width: calc(50% - 16px);
  margin-bottom: -3px;
}
@media screen and (max-width: 1199px) {
  .about-us__img {
    width: 100%;
    order: -1;
    height: 400px;
  }
}
.about-us__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.about-us__heading {
  color: #fff;
  text-align: left;
}

.about-us__description {
  color: #fff;
  margin-bottom: 32px;
}
@media screen and (max-width: 1199px) {
  .about-us__description {
    margin-bottom: 12px;
  }
}

/* Colors */
/* Menu */
#mainMenu .search-button {
  display: block;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  margin: auto 10px;
  transition: all 200ms ease-in-out;
  border-radius: 4px;
  position: relative;
  transition: background 220ms ease-in-out;
}
#mainMenu .search-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/search-icon.svg");
  background-size: 2.8rem auto;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 220ms ease-in-out;
}
#mainMenu .search-button:hover {
  background-color: #00A1B5;
}
#mainMenu .search-button:hover:before {
  filter: brightness(0) invert(1);
  transform: scale(0.8);
}

.ss-result {
  padding: 10px;
  margin: 20px 0 40px;
}
.ss-result h4 {
  font-size: 24px;
  display: inline-block;
  margin-bottom: 14px;
  position: relative;
  font-weight: 700;
}
.ss-result h4:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #00A1B5;
  position: absolute;
  left: 0;
  bottom: -4px;
}
.ss-result h4 a {
  color: #333;
}
.ss-result mark {
  font-weight: 600;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 6px;
}
.ss-result p {
  margin-bottom: 8px;
}
.ss-result .ss-result__badge {
  display: inline-block;
  font-size: 11px;
  padding: 6px 11px;
  background-color: #00A1B5;
  color: #fff;
  margin-left: 10px;
  border-radius: 8px;
  vertical-align: top;
}
.ss-result .ss-result__badge.badge-red {
  background-color: #00A1B5;
}
.ss-result .ss-result__badge.badge-blue {
  background-color: #6bc537;
}
.ss-result .ss-result__link {
  font-weight: 600;
  position: relative;
  color: #333;
}
.ss-result .ss-result__link:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #00A1B5;
  position: absolute;
  left: 0;
  bottom: -4px;
}

.ss-search-form__input {
  padding: 0 8px;
}

.search-modal {
  position: fixed;
  width: 100%;
  height: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1080;
  background: #eee;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 250ms ease-in-out;
}
.search-modal.shown {
  display: flex;
  animation: toFullHeight 300ms ease-in-out forwards;
}
.search-modal input {
  background-color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 20px 70px;
  border: none;
  border-radius: 60px;
  margin: 0 auto 0;
  display: block;
}
.search-modal button.ss-search-form__submit {
  font-size: 22px;
  margin: 20px;
  border: none;
}
.search-modal .close-modal.close-modal-btn {
  font-size: 22px;
  font-weight: 700;
  display: inline-block;
  color: #333;
  margin: 10px;
}
.search-modal .close-modal.close-modal-cross {
  position: absolute;
  width: 50px;
  height: 50px;
  display: block;
  border: 3px solid #757575;
  top: 30px;
  right: 30px;
  border-radius: 50%;
}
.search-modal .close-modal.close-modal-cross:before, .search-modal .close-modal.close-modal-cross:after {
  content: "";
  position: absolute;
  width: 60%;
  height: 60%;
  top: 50%;
  left: 50%;
  height: 3px;
  display: block;
  background-color: #757575;
  transform: translate(-50%, -50%) rotate(45deg);
}
.search-modal .close-modal.close-modal-cross:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.search-modal .ss-search-form__results.ss-search-form__results--visible {
  padding: 30px 30px 10px;
  background: #fff;
  border-radius: 30px;
}
.search-modal .ss-search-form__results.ss-search-form__results--visible .ss-autocomplete-result {
  font-size: 2.2rem;
  font-weight: 700;
  text-decoration: underline;
}
.search-modal .ss-search-form__results.ss-search-form__results--visible .ss-autocomplete-result a {
  color: #27292E;
}
.search-modal .ss-search-form__results.ss-search-form__results--visible .ss-autocomplete-result .ss-autocomplete-result__badge {
  display: inline-block;
  background-color: #00A1B5;
  font-size: 1.2rem;
  color: #fff;
  padding: 4px 12px;
  border-radius: 6px;
  margin-left: 10px;
}

@keyframes toFullHeight {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .search-modal input {
    display: table;
    width: 100%;
    max-width: 100%;
    font-size: 24px;
    padding: 20px 25px;
  }
  .search-modal .close-modal.close-modal-cross {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 555px) {
  .search-modal input {
    width: 100%;
    font-size: 18px;
  }
}
@media (max-width: 990px) {
  #mainMenu .search-button {
    margin-left: auto;
  }
}